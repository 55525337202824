<template>
<main class="error-page" data-color="#B9831C">
  <h1 ref="number">404</h1>
  <h2 ref="title">
    <span>
      <span class="u-split-container">
        <span class="split-line">
          Well,
        </span>
      </span>
    </span>
    <span>
      <span class="u-split-container">
        <span class="split-line">
          that was
        </span>
      </span>
    </span>
    <span>
      <span class="u-split-container">
        <span class="split-line">
          unexpected!
        </span>
      </span>
    </span>
  </h2>
  <router-link ref="circle" to="/">
    <span class="link__bg"></span>
    <p>
      Back<br>to Home
    </p>
  </router-link>
</main>
</template>

<script>
import { gsap } from 'gsap';
import { init, enter } from '@/scripts/animations';

export default {
  name: 'ErrorPage',

  methods: {
    enter() {
      init.get('paragraph')(this.$refs.number);
      init.get('bigTitle')(this.$refs.title);
      gsap.set(this.$refs.circle.$el, { scale: 0 });

      const tl = gsap.timeline();

      tl.addLabel('start')
        .add(enter.get('paragraph')(this.$refs.number), 'start')
        .add(enter.get('bigTitle')(this.$refs.title), 'start+=0.3')
        .to(this.$refs.circle.$el, {
          scale: 1,
          duration: 1,
          ease: 'coor',
        }, 'start+=0.6');

      return tl;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-page {
  @extend %full;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;

  text-align: center;

  h1 {
    margin-top: 10vh;
    font-weight: normal;
    font-size: 18px;
  }

  h2 {
    font-family: 'Tropiline';
    font-weight: normal;
    margin-top: 25px;
    font-size: rem(55px);
    font-size: calc(55px * var(--rw));
    line-height: 1;
    color: $white;

    @include mq(ipadP) {
      font-size: rem(120px);
      font-size: calc(120px * var(--rw));
    }

    span {
      display: block;

      &:nth-child(2) {
        transform: translateY(-15%);
      }
      &:nth-child(3) {
        transform: translateY(-30%);
      }
    }
  }

  a {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: $black;
      border-radius: 50%;
      transition: transform 0.5s cubic-bezier(.82,.89,.31,1.6);
    }

    p {
      position: relative;

      color: $white;
      font-family: 'Tropiline';
      text-align: center;
      line-height: 1.2;
      font-size: 14px;
    }

    @include mq($and: $is-not-touch-device) {
      &:hover span {
        transform: scale(1.15);
      }
    }
  }
}
</style>
